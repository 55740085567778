/*  ========================================================================== */
/*  @group MainContent Homepage */
/*  ========================================================================== */

.l-eventshop {
    position: relative;
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    padding: 1em 0 0 1em;

    &>.nextevent {
        display: flex;
        flex: 1 100%;
        background-color: #FFF;
        padding: 1em;

        &>.center {
            align-self: center;
        }

        & .nextevent__featured {
            font-family: $font-family-serif;
            line-height: 96%;
            font-size: 1.8em;
            text-transform: uppercase;
            margin-bottom: 0.25em;
            text-align: center;
        }

        &>h3 {
            margin-bottom: 10px;
        }

    }

    &>.webshop {
        flex: 1 100%;
        background-color: #663333;
        padding: 1em;
        margin-top: 1em;
    }

    &>.webshop>h3 {
        color: #FFF;
        margin: 0;
    }

    &>.webshop>figure {
        width: 100%;
        padding: 0.5em;
    }
}