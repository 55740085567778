/*  ========================================================================== */
/*  @group Text */
/*  ========================================================================== */

.lead {
    font-size: 120%;
    line-height: 1.3;
}

.initialism {
    font-size: 85%;
    text-transform: uppercase;
}

.note {
    color: $color-note;
    font-style: italic;
    font-size: 80%;
    margin-bottom: .5em;
}

.more {
    font-weight: bold;

    &::after {
        content: '\2192';
        margin-left: .5em;
    }
}

/*  ========================================================================== */
/*  @group Opt-in Typography */
/*  ========================================================================== */

.copy {

    a {
        border-bottom: 1px dotted $color-link-default;
    }

}



/*  ========================================================================== */
/*  @group Navigation */
/*  ========================================================================== */


nav.social-media {

    float: right;
    margin-top: 5px;
    margin-left: auto;

    &>ul>li {
        margin-left: 0.5em;
    }

    &>ul>li.facebook {
        float: left;
        // @include hide-text;

        &>a {
            // @include use-sprite(icon_facebook_square_white);
        }
    }

    &>ul>li.twitter {
        float: left;
        // @include hide-text;

        &>a {
            // @include use-sprite(icon_twitter_square_white);
        }
    }

    &>ul>li.instagram {
        float: left;
        // @include hide-text;

        &>a {
            // @include use-sprite(icon_instagram_square_white);
        }
    }

    &>ul>li.youtube {
        float: left;
        // @include hide-text;

        &>a {
            // @include use-sprite(icon_youtube_square_white);
        }
    }

    &>ul>li.pinterest {
        float: left;
        // @include hide-text;

        &>a {
            // @include use-sprite(icon_pinterest_square_white);
        }
    }

    @include breakpoint(max-width $width-smartphonelandscape) {
        display: none;
    }


}



/*  ========================================================================== */
/*  @group Buttons */
/*  ========================================================================== */



.social-share-container {
    float: right;
    // margin-top: -40px;
}

ul.SocialCustomMenu {

    list-style: none;

    &>li {
        float: left;
        // @include hide-text;
        margin-left: 1px;
    }


    &>li.scmTwitter>a {
        // @include use-sprite(twitter-share-passive);
    }

    &>li.scmTwitter>a:hover {
        // @include use-sprite(twitter-share-hover);
    }

    &>li.scmFacebook>a {
        // @include use-sprite(facebook-share-passive);
    }

    &>li.scmFacebook>a:hover {
        // @include use-sprite(facebook-share-hover);
    }

    &>li.scmGoogleplus>a {
        // @include use-sprite(googleplus-share-passive);
    }

    &>li.scmGoogleplus>a:hover {
        // @include use-sprite(googleplus-share-hover);
    }

    &>li.scmPinterest>a {
        // @include use-sprite(pintrest-share-passive);
    }

    &>li.scmPinterest>a:hover {
        // @include use-sprite(pintrest-share-hover);
    }


}



/*  ========================================================================== */
/*  @group MainContent Athlete */
/*  ========================================================================== */


.athlete {
    // @include span-columns(6, 12);
    flex-basis: 51%;
    background-color: $color-secondary;
    margin-bottom: 1em;
    padding: 2em;
    color: #FFF;

    @include breakpoint(max-width $width-smartphonelandscape) {
        flex-basis: 100%;
    }
}

.facts-and-figures {
    // @include span-columns(6, 12);
    flex-basis: 51%;
    background-color: #FFF;
    margin-bottom: 1em;
    padding: 2em;

    @include breakpoint(max-width $width-smartphonelandscape) {
        flex-basis: 100%;
    }
}

.content-half {
    // @include span-columns(6, 12);
    flex-basis: 51%;
    background-color: #FFF;
    margin-bottom: 1em;
    padding: 2em;

    @include breakpoint(max-width $width-smartphonelandscape) {
        flex-basis: 100%;
    }
}

.twitfeed {
    // @include span-columns(6, 12);
    flex-basis: 51%;
    background-color: #FFF;
    padding: 1em;
    margin-bottom: 1em;

    @include breakpoint(max-width $width-smartphonelandscape) {
        flex-basis: 100%;
    }
}


/*  ========================================================================== */
/*  @group MainContent Pictures */
/*  ========================================================================== */


.ytbvid,
.gallery {
    // @include span-columns(2, 10);
    flex-basis: 20%;

    &:nth-child(5n) {
        // @include omega;
    }

    text-align: center;
    height: auto;
    margin-bottom: 1%;
}

.gallery a {
    display: block;
    // font-size: 0.9em;
    line-height: 1.3em;
    margin-bottom: 0.5em;
}

.grid-container {
    padding: 0;
}

.overlay {
    display: none;
    position: relative;
    z-index: 100;
    width: 100%;
    height: 100%;
    background-color: #FFF
}

img.atleet {
    margin: 0 !important;
}

.contenthover {
    padding: 20px 20px 10px 20px;
    display: inline-block;
    vertical-align: middle;
    margin-top: 30%;
    color: $color-secondary;
    // display: none;
}


/*  ========================================================================== */
/*  @group MainContent Videos */
/*  ========================================================================== */


.video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.featured-vid {
    margin-bottom: 50px;
}


/*  ========================================================================== */
/*  @group MainContent Partner */
/*  ========================================================================== */



.partner-logo {
    @include box-sizing(border-box);
    // @include span-columns(3, 12);
    flex-basis: span(3 of 12);
    @include inline-block(middle);
    background-color: #FFF;
    padding: 0.85% 0 0.85% 0.85%;
    height: 100%;
}

.partner-logo-small {
    @include box-sizing(border-box);
    // @include span-columns(3, 6);
    flex-basis: span(3 of 12);
    @include inline-block(middle);
    background-color: #FFF;
    padding: 0;
    height: 100%;

    img {
        height: auto;
    }
}

// .row img, .row-white img{
//     margin: 0;
//     width: auto;
//     height: auto;
// }


.partner-image-small {
    // @include span-columns(3 omega, 6);
    flex-basis: span(last 3 of 12);

    img {
        width: 100%;
    }
}

.partner-image {
    // @include span-columns(9 omega, 12);
    flex-basis: span(9 of 12);
}


.partner-text {
    // @include span-columns(6 omega, 12);
    flex-basis: span(6 of 12);
    @include box-sizing(border-box);
    padding-left: 1em;
}

.white-wrapper {
    // @include span-columns(6, 12);
    flex-basis: span(6 of 12);
    background-color: #FFF;
    height: auto;
}


/*  ========================================================================== */
/*  @group MainContent Event */
/*  ========================================================================== */

.event {
    background-color: #FFF;
    padding: 1em;
    margin-bottom: 1em;


    .event-participent {
        text-transform: uppercase;
        margin-right: 1em;
        padding-left: 25px;
        font-family: $font-family-serif-bold;

    }

    .event-date {
        @extend .event-participent;
        color: $color-secondary;
        background-image: image-url('bg-event-date.gif');
        background-repeat: no-repeat;
        background-position: left center;
        font-family: $font-family-serif;
        margin-bottom: 0;
    }

    .event-title {
        font-size: 24px;
        font-family: $font-family-serif;
        font-weight: 300;
        text-transform: uppercase;
        margin: 0 0 6px 0;
        word-spacing: 1px;
        padding-left: 25px;

        a {
            font-size: 0.70em;
            margin-left: 50px;
        }

    }

    .event-athletes {
        padding-left: 25px;
    }

    &>p:last-child {
        padding-left: 25px;
    }

}





/*  ========================================================================== */
/*  @group Footer*/
/*  ========================================================================== */

.sponsors {
    display: flex;
    flex-basis: 100%;
    margin: 2em 0 1em;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 1em 0;
}

footer {
    color: white;
    text-transform: uppercase;
    font-family: $font-family-serif;

    p {
        float: left;
    }
}

#TBB-sprite {
    background-image: image-url("favicon_tbb.png");
    background-position: 0 0;
    float: right;
    height: 24px;
    margin-top: -5px;
    overflow: hidden;
    text-decoration: none;
    text-indent: -9999em;
    width: 19px;
}

#TBB-tekst {
    display: inline;
    float: right;
    margin-right: 0.5em;
    white-space: nowrap;
    font-family: $font-family-sans;
    font-size: 0.65em;
}



#editpage {
    position: absolute;
    top: 5px;
    right: 10px;
    font-weight: normal;
    text-transform: uppercase;
    font-family: $font-family-serif;
}

@import "vegas", "youtube", "colorbox";
// @import "vegas", "youtube", "wookmark", "colorbox", "contactform";