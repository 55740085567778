.newscenter {
    padding: 1em 0 0 1em;

    a {
        text-align: left;
    }
}

.newsfeed {
    flex-basis: 66%;
    background-color: white;
    padding: 1em;

    &>article {
        margin-bottom: 2em;
    }
}

.tweetfeed {
    flex: 1 1 auto;
    margin-left: 1em;
    background-color: white;
    padding: 1em;

    @include breakpoint(max-width $width-smartphonelandscape) {
        margin-left: 0em;
    }
}