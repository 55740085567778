.l-pictures {
    max-width: 1140px;
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2em;
}

#tiles {
    list-style-type: none;
    position: relative;
    margin: 0;
    padding: 0;
}


#tiles li {
    width: 150px;
    background-color: #ffffff;
    border: 0px solid #dedede;
    border-radius: 0px;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    display: none;
    cursor: pointer;
    padding: 0px;
}


/**
 * Placerholder css
 */
.wookmark-placeholder {
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    background-color: #eee;
    border: 1px solid #dedede;
    z-index: -1;
}

/**
 * Grid container
 */
.tiles-wrap {
    position: relative;
    /** Needed to ensure items are laid out relative to this container **/
    margin: 10px 0;
    padding: 0;
    list-style-type: none;
    display: none;
}


/**
 * Grid items
 */
.tiles-wrap li {
    display: block;
    opacity: 0;
    text-align: center;
    list-style-type: none;
    background-color: #fff;
    float: left;
    cursor: pointer;
    width: 200px;
    padding: 4px;
    border: 1px solid #dedede;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
}

.tiles-wrap.wookmark-initialised.animated li {
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.tiles-wrap.wookmark-initialised li {
    opacity: 1;
}

.tiles-wrap li.wookmark-inactive {
    visibility: hidden;
    opacity: 0;
}

.tiles-wrap li:hover {
    background-color: #fafafa;
}

.tiles-wrap img {
    display: block;
}

.tiles-wrap a {
    color: #555;
    text-align: center;
    /* display: table-cell; */
    width: 200px;
    height: 200px;
    font-size: 2em;
    font-weight: bold;
    text-decoration: none;
}

.tile-loading:after {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #ddd;
    content: "Loading\2026";
    padding-top: 20px;
    color: #999;
}

/**
 * Filters
 */
#filters {
    list-style-type: none;
    text-align: center;
    margin: 0 5% 0 5%;
}

#filters:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
}

#filters li {
    font-size: 12px;
    float: left;
    padding: 6px 8px 4px;
    cursor: pointer;
    margin: 0 10px;
    -webkit-transition: all 0.15s ease-out;
    -moz-transition: all 0.15s ease-out;
    -o-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

#filters li:hover {
    background: #4281f5;
    color: #ffffff;
}

#filters li.active {
    background: #4281f5;
    color: #ffffff;
}