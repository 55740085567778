/*  ========================================================================== */
/*  @group Base */
/*  ========================================================================== */

/*  ========================================================================== */
/*  @group Global Colors */
/*  ========================================================================== */

body {
    color: $color-text;
    background-color: $color-background;
}

html,
body {
    height: 100%;
    width: 100%;
}


/*  ========================================================================== */
/*  @group Font Stacks */
/*  ========================================================================== */

body {
    font: $font-weight-normal #{$base-fs/16*100}%/($base-lh/$base-fs) sans-serif;
}

/* Default font stack */
html,
body,
button,
input,
select,
textarea {
    font-family: $font-family-default;
}

/* Display font stack */
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font-family-display;
}

/* Alternate font stack */
blockquote,
figcaption {
    font-family: $font-family-serif;
}

/* Caption font stack, should be suitable for smaller sizes */
[role=contentinfo],
.caption {
    font-family: $font-family-caption;
}

/* Monospace font stack, for code samples and preformatted content */
pre,
code,
kbd,
samp {
    font-family: $font-family-monospace;
}


/*  ========================================================================== */
/*  @group Typography */
/*  ========================================================================== */

/*  @group Headings */
/*  ========================================================================== */

h1,
h2,
h3,
h4,
h5,
h6 {
    font-style: normal;
}

h1,
h2,
h3 {
    font-weight: $font-weight-semibold;
}

h4,
h5,
h6 {
    font-weight: $font-weight-normal;
}

h1 {
    font-size: 28px;
    font-family: $font-family-serif;
    font-weight: 300;
    text-transform: uppercase;
    margin: 0 0 6px 0;
    word-spacing: 1px;
}

h2 {
    font-size: 20px;
    font-family: $font-family-serif;
    font-weight: 300;
    text-transform: uppercase;
    margin: 20px 0 10px 0;
    color: $color-secondary;
}

h3 {
    font-size: 14px;
    font-family: $font-family-serif;
    font-weight: 300;
    text-transform: uppercase;
    margin: 0 0 3px 0;
    word-spacing: 1px;
}

h4 {
    font-size: rs(21);
    margin: rs(21, 21) 0 rs(7, 21) 0;
}

h5 {
    font-size: rs(18);
    margin: rs(21, 18) 0 rs(7, 18) 0;
}

h5 {
    font-size: rs(16);
    margin: rs(21, 16) 0 rs(7, 16) 0;
}

/*  @group Links */
/*  ========================================================================== */
// Declared: link, visited, hover, active

a {
    text-decoration: none;
    word-wrap: break-word;
    //font-weight: bold;
}

a:focus {
    outline: none;
}

a:hover,
a:active {
    outline: 0;
}

a {
    color: $color-link-default;
}

a:visited {
    color: $color-link-default;
}

a:hover {
    cursor: pointer;
    color: $color-link-hover;
}

a.active {
    color: $color-secondary;
}

/*  @group Text Level Elements */
/*  ========================================================================== */

/* Default margins */
p,
ul,
ol,
dl,
td {
    margin: 0 0 rs($base-lh/2) 0;
    font-size: 0.90em;
}

/* Lists */
// ul, ol, dl            { padding: 0; }
// ul ul, ol ol, dl dl   { margin-bottom: 0; }
// ul li, ol li          { margin-left: rs($base-lh,$base-fs); }
// ul li                 { list-style: disc outside; }
// ol li                 { list-style: outside decimal; }
// ul li ul              { margin: rs($base-lh/2) 0 0 rs($base-fs); }
// dl dt                 { font-weight: bold; }
// dl dd                 { margin: 0 0 rs($base-fs) 0; }


/* Text Marking */
b,
strong,
mark {
    font-weight: bold;
}

dfn,
mark {
    font-style: italic;
}

ins,
mark {
    color: invert($color-highlight);
    background-color: $color-highlight;
}

ins {
    text-decoration: none;
}

del {
    text-decoration: line-through;
}

abbr[title],
dfn[title] {
    cursor: help;
    border-bottom: 1px dotted;
}

// Position subscript and superscript content without affecting line-height: h5bp.com/k
sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sup {
    top: -0.5em;
}

sub {
    bottom: -0.25em;
}

/*
         * A better looking default horizontal rule
         */
hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid $color-muted;
    margin: rs($base-lh/2) 0;
    padding: 0;
}

/*
         * Remove text-shadow in selection highlight: h5bp.com/i
         * These selection rule sets have to be separate.
         * Customize the background color to match your design.
         */
::-moz-selection {
    background: $color-selection;
    text-shadow: none;
}

::selection {
    background: $color-selection;
    text-shadow: none;
}

/*  @group Embedded Content */
/*  ========================================================================== */

img {
    vertical-align: middle;
}

/* _width hack is for IE6 and below */
img,
embed,
object,
video,
iframe {
    max-width: 100%;
    _width: 100%;
}

img {
    border: 0;
    -ms-interpolation-mode: bicubic;
    vertical-align: middle;
}

/* Correct overflow displayed oddly in IE9 */
svg:not(:root) {
    overflow: hidden;
}


video {
    height: auto;
}


/*  @group Figures */
/*  ========================================================================== */

figure {
    margin: 0;
    @include inline-block();
}

/*  @group Quotes */
/*  ========================================================================== */

q {
    quotes: none;
}

q:before,
q:after {
    content: "";
    content: none;
}

q,
cite,
blockquote {
    font-style: italic;
}

blockquote {}

/*  @group Code */
/*  ========================================================================== */

pre {
    white-space: pre;
    white-space: pre-wrap;
    word-wrap: break-word;
}

code {
    padding: 0 .2em;
    color: $color-code;
    background-color: $color-code-background;
}

pre,
.code {
    color: $color-pre;
    background-color: $color-pre-background;
    margin-bottom: rs($base-lh/2);
    padding: 1em rs($base-lh);
}

pre code,
.code code {
    background-color: transparent;
    border: none;
    padding: 0;
}

.code li {
    list-style-type: decimal-leading-zero;
}

/*  ========================================================================== */
/*  @group Tables */
/*  ========================================================================== */

table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
}

td {
    vertical-align: top;
    width: 50%;
}

/*  ========================================================================== */
/*  @group Forms */
/*  ========================================================================== */

// Remove default fieldset styles.
fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

// Allow only vertical resizing of textareas.
textarea {
    resize: vertical;
}