.nav-primary {
    font-size: 1.2em;
    text-transform: uppercase;
    font-family: $font-family-serif-bold;
    //font-weight: 600;

    &>ul>li {
        margin-right: 1.4em;
    }

    &>ul>li>a:visited {
        color: white;
    }

    &>ul>li>a.active {
        color: $color-secondary;
    }
}

.nav-bg {
    width: 100%;
    background-color: black;
    margin-bottom: 1em;
}

.breadbrumb {
    margin-bottom: 0.2em;
    flex-basis: 100%;
}

.nav,
.nav ul,
nav ul,
nav ol {

    list-style: none;
    margin: 0;
    padding: 7px 0 3px;


    &>li,
    &>li>a {
        @include inline-block();
        color: white;
    }

    &>li>a:hover {
        color: $color-secondary;
    }


}

.nav-stacked {
    &>li {
        display: list-item;
    }

    &>li>a {
        display: block;
    }
}

.nav-banner {
    text-align: center;
}

.nav-inline {
    li::before {
        color: #FFF;
        content: " > ";
        margin: 0 .5em 0 .5em;
    }

    li:first-child::before {
        content: "";
        margin: 0;
    }


    &>li>a:visited {
        color: #FFF;
    }

    &>li>a:hover {
        color: $color-secondary;
    }

}


.MarkupPagerNav {
    float: left;
    margin: 1em 0;
    font-family: Arial, sans-serif;
    padding: 0;
}

.MarkupPagerNav li {
    float: left;
    list-style: none;
    margin: 0;
}

.MarkupPagerNav li a,
.MarkupPagerNav li.MarkupPagerNavSeparator {
    display: block;
    float: left;
    padding: 2px 9px;
    color: #fff;
    background: #000;
    margin-right: 3px;
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
}

.MarkupPagerNav li.MarkupPagerNavOn a,
.MarkupPagerNav li a:hover {
    color: #fff;
    background: $color-secondary;
    text-decoration: none;
}

.MarkupPagerNav li.MarkupPagerNavSeparator {
    display: inline;
    color: #777;
    background: #d2e4ea;
    padding-left: 3px;
    padding-right: 3px;
}