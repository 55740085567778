// -------------------------------------------------------- //
// Mixins
// -------------------------------------------------------- //

// Media Queries
// =============
// @see http://jakearchibald.github.com/sass-ie/

$fix-mqs: false !default;
@mixin respond-min($width) {
  @if $fix-mqs {
    @if $fix-mqs >= $width {
      @content;
    }
  }
  @else {
    @media screen and (min-width: $width) {
      @content;
    }
  }
}
@mixin respond-max($width) {
  @if $fix-mqs {
    @if $fix-mqs >= $width {
      @content;
    }
  }
  @else {
    @media screen and (max-width: $width) {
      @content;
    }
  }
}

$old-ie: false !default;
@mixin old-ie {
  @if $old-ie {
    @content;
  }
}


// Font-size
// =========
// rem font-size with px fallback
@mixin font-size( $value ) {
  font-size: ( $value ) * 1px;
  font-size: ( $value / $base-font-size ) * 1rem;
}
// Box Sizing
// ==========

@mixin box-sizing ($type: border-box) {
  // content-box | border-box | inherit
  -webkit-box-sizing: $type;
     -moz-box-sizing: $type;
          box-sizing: $type;
}


// Inline Block
// ============
// Custom version of http://bit.ly/thoughtbot-bourbon-inline-block

@mixin inline-block($alignment: baseline) {
  display: inline-block;
  @if $alignment and $alignment != none {
    vertical-align: $alignment;
  }
  @include old-ie {
    zoom:1;
    *display:inline;
    *vertical-align:auto;
  }
}


// Box reset
// =========
// Reset border, margin, and padding
@mixin box-reset() {

  margin: 0;
  padding: 0;
  border: 0 none;

}


// Button
// ======
// Base button styles, based on https://github.com/csswizardry/beautons
@mixin button() {

    @include box-reset();
    @include inline-block( middle );
    white-space: nowrap;
    font-size: 100%;
    cursor: pointer;
    overflow: visible;

}
