.ui-button {

    @include button();

    height: 2em;
    line-height: 2;
    padding-right: .75em;
    padding-left: .75em;
    width: 75%;
    color: #FFF;
    text-transform: uppercase;
    font-family: $font-family-serif;
    font-size: 1.4em;
    background-color: $color-secondary;
    margin-left: 15.5%;

    @include breakpoint(max-width $width-smartphonelandscape) {
        width: 100%;
        margin-left: 0%;
    }

}

.ui-button,
.ui-button:hover,
.ui-button:active,
.ui-button:focus,
.ui-button:visited {
    color: $color-text-alt;
    text-decoration: none;
}

.ui-button:hover,
.ui-button:active {
    background-color: darken($color-primary, 10%);
}

.ui-button:active,
.ui-button:focus {
    outline: none;
}