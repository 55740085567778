.team {
    flex: 1;
    // background-color: $color-secondary;
    padding: 1em 0 0 1em;

    & .fade-caption {
        position: relative;
        display: flex;
        align-items: center;

        &>.member {
            font-family: "josefin_sansregular", sans-serif;
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            padding: 0.5em;
            position: absolute;
            height: 100%;
            width: 100%;
            z-index: 10;
            background-color: rgba(255, 255, 255, 0.6);
            top: 0;
            left: 0;
            opacity: 0;

            &.hover {
                opacity: 1;
            }

        }
    }

    & .caption {

        margin: 0;
        padding: 0 1em 1em 0;
        text-align: right;
        text-decoration: none;
        list-style-type: none;

        & a {
            margin: 0;
            padding: 0;
            font-size: 16px;
        }

    }

    & .bg-red {
        background-color: $color-secondary;
    }



    // &>a {
    //     flex: 0 0 30%;
    //     position: relative;
    //     margin: 1.5%;
    //     display: flex;
    //     justify-content: center;
    //     flex-wrap: wrap;

    //     &>img {
    //         width: 300px;
    //         max-width: 100%;
    //         max-height: 300px;
    //     }

    //     &>.caption {
    //         display: none;
    //         font-size: 14px;
    //         position: absolute;
    //         bottom: 0;
    //         left: 0;
    //     }
    // }


    // figcaption {
    //     background-color: $color-secondary;
    //     padding: 1em;
    // }

    // .figcaption {
    //     font-family: $font-family-serif;

    //     display: none;
    //     font-size: 0.90em;
    //     line-height: 100%;
    //     padding-top: 40%;
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 100%;
    //     text-align: center;
    //     background-color: rgba(255, 255, 255, 0.6);
    //     color: black;
    // }
}