// Variables

// Colour Scheme
// =============
// To avoid confusion with CSS properties,
// colour scheme variable names use american english _color_

// Main colour palette
$color-primary: #000;
$color-secondary: #ed1c24;
$color-highlight: #999999;
$color-muted: #eef;
$color-note: #666;

// Common colours
$color-text: #663333;
$color-text-alt: #fff;
$color-background: #fff;
$color-border: #ddd;

// Links
$color-link-default: #ff0000;
$color-link-visited: #663333;
$color-link-hover: #000;
$color-link-active: #000;

// Code
$color-code: #333;
$color-code-background: #f3f3f3;
$color-pre: #d4d4d4;
$color-pre-background: #333;

// Selections
$color-selection: #b3d4fc;

// Messages
$color-message: #f4ecbb;
$color-success: #2cde2c;
$color-warning: #cf8600;
$color-important: #d00;
$color-notice: #66b;


// Base Sizes
// ==========

// Included shorthands as these may be used a lot.

$base-font-size: 16;
$base-line-height: 24;
$base-fs: $base-font-size;
$base-lh: $base-line-height;


// Font Stacks
// ===========

@font-face {
    font-family: 'josefin_sansbold';
    src: url('../fonts/josefinsans-bold-webfont.eot');
    src: url('../fonts/josefinsans-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/josefinsans-bold-webfont.woff') format('woff'),
         url('../fonts/josefinsans-bold-webfont.ttf') format('truetype'),
         url('../fonts/josefinsans-bold-webfont.svg#josefin_sansbold') format('svg');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'josefin_sansregular';
    src: url('../fonts/josefinsans-regular-webfont.eot');
    src: url('../fonts/josefinsans-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/josefinsans-regular-webfont.woff') format('woff'),
         url('../fonts/josefinsans-regular-webfont.ttf') format('truetype'),
         url('../fonts/josefinsans-regular-webfont.svg#josefin_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

$font-family-sans: "Helvetica Neue", sans-serif;
$font-family-serif: 'josefin_sansregular', sans-serif;
$font-family-serif-bold: 'josefin_sansbold', sans-serif;
$font-family-display: "Helvetica Neue", sans-serif;
$font-family-caption: Verdana, sans-serif;
$font-family-monospace: "Menlo", "Bitstream Vera Sans", Monaco, "Andale Mono", "Lucida Console", "Droid Mono", monospace;

$font-family-default: $font-family-sans;


// Font Weights
// ============

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-semibold: 700;
$font-weight-bold: 900;


// Breakpoints
// ===========
// There are no common breakpoints so these are just a suggestion
// You'll need to define your own breakpoints to suit your design

$breakpoint-narrow: 35em;
$breakpoint-mid: 48em;
$breakpoint-wide: 60em;
$breakpoint-huge: 100em;
