/* ==|====================
   Module/Slicknav -- 
   ======================= */

.slicknav_menu {
    display: none;
}

@media screen and (max-width: 40em) {

    /* #menu is the original menu */
    #nav {
        display: none;
    }

    .slicknav_menu {
        display: block;
    }
}


.slicknav_btn {
    position: relative;
    display: block;
    vertical-align: middle;
    float: left;
    padding: 0.438em 0.625em 0.438em 0.625em;
    line-height: 1.125em;
    cursor: pointer;

    .slicknav_icon-bar+.slicknav_icon-bar {
        margin-top: 0.188em;
    }
}

.slicknav_menu {
    *zoom: 1;

    .slicknav_menutxt {
        display: block;
        line-height: 1.188em;
        float: left;
    }

    .slicknav_icon {
        float: left;
        margin: 0.188em 0 0 0.438em;
    }

    .slicknav_no-text {
        margin: 0;
    }

    .slicknav_icon-bar {
        display: block;
        width: 1.125em;
        height: 0.125em;
    }

    &:before {
        content: " ";
        display: table;
    }

    &:after {
        content: " ";
        display: table;
        clear: both;
    }
}

.slicknav_nav {
    clear: both;

    ul {
        display: block;
    }

    li {
        display: block;
    }

    .slicknav_arrow {
        font-size: 0.8em;
        margin: 0 0 0 0.4em;
    }

    .slicknav_item {
        cursor: pointer;

        a {
            display: inline;
        }
    }

    .slicknav_row {
        display: block;
    }

    a {
        display: block;
    }

    .slicknav_parent-link a {
        display: inline;
    }
}

.slicknav_brand {
    float: left;
}


/***** theme ***/

//colors
$color_0: rgba(255, 255, 255, 0.75);
$color-primary: #fff;
$color_hover_bg: red;
$color_hover_text: #222;

.slicknav_menu {
    font-size: 16px;
    box-sizing: border-box;
    padding: 0px;

    * {
        box-sizing: border-box;
    }

    .slicknav_menutxt {
        color: $color-primary;
    }

    .slicknav_icon-bar {
        background-color: $color-primary;
    }
}

.slicknav_btn {
    margin: 5px 5px 6px;
    text-decoration: none;
    background-color: $color-secondary;
}

.slicknav_nav {
    color: $color-primary;
    margin: 0;
    padding: 0;
    font-size: 0.875em;
    list-style: none;
    overflow: hidden;

    ul {
        list-style: none;
        overflow: hidden;
        padding: 0;
        margin: 0 0 0 20px;
    }

    .slicknav_row {
        padding: 5px 10px;
        margin: 2px 5px;
        background: $color_hover_bg;

        &:hover {
            background: $color-secondary;
            color: #fff;
        }
    }

    a {
        padding: 5px 10px;
        margin: 2px 5px;
        text-decoration: none;
        color: $color-primary;

        &:hover {
            color: #fff;
            background-color: $color-secondary;
        }

    }

    .slicknav_txtnode {
        margin-left: 15px;
    }

    .slicknav_item a {
        padding: 0;
        margin: 0;
    }

    .slicknav_parent-link a {
        padding: 0;
        margin: 0;
    }
}

.slicknav_brand {
    color: $color-primary;
    font-size: 18px;
    line-height: 30px;
    padding: 7px 12px;
    height: 44px;
}