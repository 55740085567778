/*  ========================================================================== */
/*  @group State (& Helpers) */
/*  ========================================================================== */

/*
    * Image replacement
    */
.ir {
    background-color: transparent;
    border: 0;
    overflow: hidden;
    // IE 6/7 fallback
    *text-indent: -9999px;

    &:before {
        content: "";
        display: block;
        width: 0;
        height: 150%;
    }
}

/*
    * Hide from both screenreaders and browsers: h5bp.com/u
    */
.hidden,
.is-hidden {
    display: none !important;
    // visibility: hidden;
}

.is-nomargin {
    margin: 0;
}

/*
    * Hide only visually, but have it available for screenreaders: h5bp.com/v
    *
    * .focassable Extends the .visuallyhidden class to allow the element to be focusable
    * when navigated to via the keyboard: h5bp.com/p
    */
.visuallyhidden,
.is-visuallyhidden,
    {

    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;

    &.focusable:active,
    &.focusable:focus {
        clip: auto;
        height: auto;
        margin: 0;
        overflow: visible;
        position: static;
        width: auto;
    }

}

/*
    * Hide visually and from screenreaders, but maintain layout
    */
.invisible,
.is-invisible {
    visibility: hidden;
}

.is-capitalized {
    text-transform: uppercase;
    display: block;
    text-decoration: underline;
    font-family: $font-family-serif;
    padding: 0.5em 0;

    @include breakpoint(max-width $width-smartphonelandscape) {
        font-size: 0.8em;
    }
}

.is-about {
    font-family: $font-family-serif-bold;
}

.is-red {
    color: $color-secondary;
}

.is-inverted:hover,
.is-inverted:active {
    outline: 0;
}

.is-inverted {
    color: #FFF;
}

.is-inverted:visited {
    color: #FFF;
}

.is-inverted:hover {
    cursor: pointer;
    color: #000;
}

.is-inverted:active {
    color: #000;
}

.is-table {
    display: table;
}

.no-margin {
    margin: 0 0 12px 0;
}

.is-white-padding-content {
    @include inline-block;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 2em;
    margin-bottom: 1.4em;


    &>.row {
        margin: 1.2em 0;
        float: left;
        height: auto;
    }

    .row-white {
        background-color: #FFF;
        float: left;
    }


    .is-25-pr {
        float: left;
        width: 25%;

        img {
            float: right;
            margin: 0;
        }
    }

    .is-50-pr {
        float: left;
        width: 50%;

        img {
            float: right;
            margin: 0;
        }
    }

    .is-75-pr {
        float: left;
        width: 75%;

        img {
            float: right;
            margin: 0;
        }
    }
}


/*
    * Clearfix: contain floats
    */
.group,
.cf,
.clearfix {

    &:before,
    &:after {
        content: " ";
        display: table;
    }

    &:after {
        clear: both;
    }

    *zoom: 1;
}

.clear {
    clear: both;
}