// grid met 5 grid items

.l-grid {

    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    // padding: 2em 3em;
    padding: 1em;
    margin-bottom: 0;

    &>.grid__item {

        margin-right: 10px;
        margin-bottom: 1em;
    }

    &--five {

        padding: 1em 3em;

        @include breakpoint(max-width $width-smartphonelandscape) {
            padding: 1em 1em;
        }

        &>.grid__item {
            flex-basis: calc((100% - 10px * 4) / 5);

            &:nth-child(5n+5) {
                margin-right: 0;
            }

            @include breakpoint(max-width $width-smartphonelandscape) {
                flex-basis: 100%
            }
        }

    }

    &--tree {

        &>.grid__item {
            flex-basis: calc((100% - 10px * 2) / 3);

            &:nth-child(3n+3) {
                margin-right: 0;
            }
        }

    }

}