.pictures {

    a.is-capitalized {
        padding: 0 1em 1em 1em;
    }

    &>div {
        flex: 1;
        padding: 1em 0 0 1em;
        background-color: white;
        background-clip: content-box;

        &>h2 {
            margin: 0;
            padding: 1em 1em 0 1em;
            background-color: white;
        }
    }

    .latestpictures {
        flex: 1;


        &>figure {
            background-color: white;
            padding: 1em;
        }

        &>figure>img {
            float: left;
            width: 47%;
            margin: 1.5%;
        }

        figure {
            @include inline-block;
        }
    }

    .teaminstagram,
    .flyersfotos,
    .picturelink {

        &>figure {
            background-color: white;
            padding: 1em;
        }
    }

}