.l-constrained {
    display: flex;
    flex-direction: column;
    max-width: 1140px;
    margin: 0 auto;

    &--row {
        flex-direction: row;
        flex-wrap: wrap;
        // justify-content: space-between;

        @include breakpoint(max-width $width-smartphonelandscape) {
            flex-direction: column;
            flex-wrap: nowrap;
        }
    }

    &>section,
    &>article {
        max-width: 1140px;
        margin-bottom: 3em;
    }

    & .grid {
        display: flex;
        flex-basis: 100%;
        margin: -1em 0 1em -1em;
        // justify-content: space-between;

        @include breakpoint(max-width $width-smartphonelandscape) {
            flex-direction: column;
        }

        &--pictures {
            margin: -0.5em 0 0.5em -0.5em;
        }
    }


}