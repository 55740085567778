.l-partner {
    background-color: white;
    margin: 3em 0 1em 0;

    &--logo {
        flex-basis: 30%;
        padding: 0 2em;
        align-self: center;
    }

    &--image {
        flex-basis: 70%;
    }

    &--text {
        margin-bottom: 2em;
        flex-basis: 100%;
    }

}

.mainsponsors {
    padding: 0 2em;
}


.l-sponsor {

    background-color: white;
    margin: 3em 0 1em 0;

    &--logo-small {
        flex-basis: 25%;
        padding: 0 2em;
        align-self: center;
    }

    &--image {
        flex-basis: 25%;
        align-self: center;
    }

    &--text {
        flex-basis: 50%;
        align-self: center;
        padding: 2em;
    }

}