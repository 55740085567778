@charset "utf-8";

// SCSS Toolkit v2.0.0
// David Rapson
//
// A starter toolkit for Sass/SCSS projects


/* Breakpoint */
// ======
@import "../bower_components/compass-breakpoint/stylesheets/breakpoint";

$width-desktop: 100em; // 1600/16
$width-laptop: 80em; // 1280/16
$width-tabletlandscape: 64em; // 1024/16
$width-tabletportrait: 48em; // 768/16
$width-smartphonelandscape: 40em; // 640/16
$width-smartphoneportrait: 22.500em; // 360/16


// Sass Features
// =============

@import "abstracts/variables", "abstracts/functions", "abstracts/mixins";


// The Styles
// ==========
// Styles are defined in the following order & groupings:
// Base, Layout, Module, State

// Base styles (reset + elements)
@import "common/normalize", "common/base";

// Components = minor components, e.g., navigation bar, callout, widgets etc.
// ======
@import "components/vegas", "components/wookmark", "components/module", "components/mediaqueries", "components/colorbox", "components/slicknav", "components/youtube";

// - Layout = major components, e.g., header, footer etc.
// ======
@import "layout/layout", "layout/header", "layout/constrained", "layout/navigation", "layout/buttons", "layout/team", "layout/eventshop", "layout/featured", "layout/news", "layout/pictures", "layout/grid", "layout/form", "layout/partnersponsor";

// - State  = a state is something that augments + overrides all other styles, e.g., success or error state
// ======
@import "state";

// Print styles
// ======
@import "print";