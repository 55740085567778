.l-featured {
    flex: 1;
    padding: 1em 0 0 1em;
    display: flex;
    flex-wrap: wrap;

    &>.featuredpartner {
        background-color: #FFF;
        padding: 1em;
        flex: 0 0 100%;

        &>.featuredpartner {
            background-color: #FFF;
            padding: 0.5em 1em;

            &>h2 {
                color: #FFF;
                margin: 0;
            }

            &>figure {
                text-align: center;
                margin: 1em 0;
            }

            &>figcaption {
                font-size: 14px;
            }
        }

        &>h3 {
            margin-bottom: 10px;
        }

    }

    &>.featuredvideo {
        background-color: #FFF;
        padding: 1em;
        margin-top: 1em;
        flex: 0 0 100%;

        &>.featuredvideo__video {
            width: 100%;
            text-align: center;
            margin: 1em 0 0;
            @include inline-block;

        }

        &>h3 {
            @include inline-block;
            float: left;
            margin: 0;
        }

        &>a {
            @include inline-block;
            float: right;
            margin: 0;
            font-size: 0.8em;
        }

        .wrapper {
            padding: 2em 0 0 0;
        }
    }

    &>.webshop {
        @include inline-block;
        width: 100%;
        background-color: #663333;

        padding: 0.5em;
        border-top: 0.5em solid rgba(0, 0, 0, 0.1);
        -webkit-background-clip: padding-box;
        /* for Safari */
        background-clip: padding-box;
        /* for IE9+, Firefox 4+, Opera, Chrome */
    }
}