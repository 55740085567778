/*  ========================================================================== */
/*  @group Header */
/*  ========================================================================== */

header {
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    clear: both;

    ul {
        @include inline-block();
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: space-around;

        &>li {
            align-self: center;
        }

        @include breakpoint(max-width $width-smartphonelandscape) {
            margin: 1em 0;
        }
    }

    ul.mainsponsors {
        &>li {
            margin-right: 2em;
        }

        &>li:last-child() {
            margin-right: 0;
        }
    }
}



.logo-team {
    margin: 20px 0;

    & a>img {
        margin: 0 1em;

        @include breakpoint(max-width $width-smartphonelandscape) {
            margin: 0;
        }
    }

    @include breakpoint(max-width $width-smartphonelandscape) {
        margin: 0;
        padding: 0.5em;
    }
}

.logo-uplacebmc {
    height: 90px;
    margin-left: 0 !important;
    margin-right: 2em !important;

    @include breakpoint(max-width $width-smartphonelandscape) {
        margin-right: 0 !important;
        ;
        height: auto;
    }
}

.logo-uplace {
    width: 125px;
    height: 90px;
}

.logo-bmc {
    width: 114px;
    height: 90px;
}

[role="banner"] {
    background-color: darken($color-background, 10%);
    border-bottom: 1px solid darken($color-background, 15%);
    padding: 1em 0 1em;
    margin-bottom: 1.5em;
}

.branding {
    margin: 110px 0;
}

.branding-logo {
    margin: 0;
    @include font-size(24);
}