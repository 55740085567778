.FormBuilder {

    display: flex;
    flex-wrap: wrap;


    .formrow {
        flex-basis: 80%;
        display: flex;
        margin: 1em 0;
        padding: 1em;

        @include breakpoint(max-width $width-smartphonelandscape) {
            flex-basis: 100%;
            flex-wrap: wrap;
            padding: 0em;
        }

        &>label {
            flex-basis: 15%;

            @include breakpoint(max-width $width-smartphonelandscape) {
                flex-basis: 100%;
            }
        }

        &>input {
            flex-basis: 75%;
            background-color: #FFF;
            padding: 0.5em;
            border: 0;

            @include breakpoint(max-width $width-smartphonelandscape) {
                flex-basis: 100%;
            }
        }

        &>textarea {
            background-color: #FFF;
            padding: 0.5em;
            flex-basis: 75%;
            border: 0;

            @include breakpoint(max-width $width-smartphonelandscape) {
                flex-basis: 100%;
            }
        }

        &:nth-child(4) {
            display: none;
        }
    }
}