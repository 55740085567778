.vegas-loading {
	border-radius: 10px;
	background: #000;
	background: rgba(0,0,0,0.7);
	background: url(../img/loading.gif) no-repeat center center; /* Loading Gif by http://preloaders.net/ */
	height: 32px;
	left: 20px;
	position: fixed;
	top: 20px;
	width: 32px; 
	z-index: 0;
}


.vegas-background {
	-ms-interpolation-mode: bicubic;
	image-rendering: optimizeQuality;
    max-width: none !important; /* counteracts global img modification by twitter bootstrap library */
	z-index: -2;
}

.vegas-background {
	-webkit-user-select: none;
	 -khtml-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}