/*  ========================================================================== */
/*  @group Layout */
/*  ========================================================================== */

// ---------------------------------------------------------------------------
// Basic Grid

$total-columns : 12;
$column-width : 4.5em;
$gutter-width : 0.5em;
// $grid-padding   : $gutter-width;
$grid-padding : 0;

$show-grid-backgrounds : true;

/*  ========================================================================== */
/*  @group Layout Helpers */
/*  ========================================================================== */

* {
    @include box-sizing(border-box);
}

/*  ========================================================================== */
/*  @group Main */
/*  ========================================================================== */

.l-countdown {
    margin-bottom: 90px;
}